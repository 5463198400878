import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user.js'
import { RETURN_TO } from '@/composables/constants'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'Login',
      components: {
        default: () => import('@/components/pages/LoginPage.vue'),
      },
      beforeEnter: () => {
        const user = useUserStore()
        if (user.authenticated ) {
          return { name: 'Home' }
        }
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      beforeEnter: (to, from, next) => {
        const userStore = useUserStore();
        userStore.logOut().then(() => {
          next({ name: 'Login' });
        }).catch((error) => {
          console.error("Logout failed:", error);
        });
      }
    },
    {
      path: '/password/edit',
      name: 'ResetPassword',
      components: {
        default: () => import('@/components/pages/ResetPasswordPage.vue'),
      },
    },

    {
      path: '/',
      name: 'Home',
      components:{
        default:  () => import('@/components/pages/HomePage.vue'),
        Header: () => import('@/components/core/TheHeader.vue')
      }
    },
    {
      path: '/user_redirection/:external_id',
      name: 'UserRedirection',
      components: {
        default: () => import('@/components/pages/UserRedirectionPage.vue'),
      }
    },
    {
      path: '/user/:id',
      name: 'User',
      components: {
        default: () => import('@/components/pages/UserPage.vue'),
      },
    },

    {
      path: '/user/:userId/conversation/:id',
      name: 'Conversation',
      components: {
        default: () => import('@/components/pages/user/ConversationPage.vue'),
      }
    }
  ]
})

router.beforeEach(async (to) => {
  const user = useUserStore()
  if (!user.isAuthenticated() && to.name !== 'Login' && to.name !== 'ResetPassword') {
    sessionStorage.setItem(RETURN_TO, to.fullPath)

    return { name: 'Login' }
  }
})

export default router
